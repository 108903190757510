<template>
  <v-dialog v-if="event" v-model="visible" max-width="650">
    <v-card>
        <v-card-title>
            <span class="headline">Add a Last One Standing challenge</span>
        </v-card-title>

        <v-card-text>
          <p>
            A Last One Standing challenge is a series of rounds where each participant must complete the given goal in each round to be able to start in the next round.
          </p>

          <v-text-field
            v-model="request.name" 
            label="Name of the challenge"
            />

          <v-select
            :items="$helpers.prefixSelectListWith(siteData.activities, {type: null, text:'Any sport'})"
            v-model="request.activity_type" 
            item-value="type"
            item-text="text"
            label="Allowed Sport"
            />
          
          <DateAndTimeWithTimeZonePicker v-model="request.start_time" ref="timePicker" :timeZone="event.timeZoneOlson" label="Start date of challenge" timeLabel="Start time of 1st round" />

          <v-row>
            <v-col cols="6">
              <ToggleButtonInput v-model="goalType" label="Type of goal" :items="[ { type: 'DISTANCE', text: 'Distance' }, { type: 'ACTIVE_TIME', text: 'Active Time' }, { type: 'STEPS', text: 'Steps' }, ]" />
            </v-col>
            <v-col cols="6">
              <DistanceTextArea
                v-if="goalType == 'DISTANCE'"
                v-model="request.slot_distance" 
                :mode="$helpers.UnitType.DISTANCE"
                :label="`Minimum distance per round (in ${event.unit == 'METRIC' ? 'km' : 'mi'})`" 
                />
              <DistanceTextArea
                v-if="goalType == 'ACTIVE_TIME'"
                v-model="request.slot_active_time" 
                :mode="$helpers.UnitType.NUMBER"
                :multiplier="60"
                :label="`Minimum active time (in minutes)`" 
                />
              <DistanceTextArea
                v-if="goalType == 'STEPS'"
                v-model="request.slot_steps" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Minimum number of steps`" 
                />

            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <ToggleButtonInput v-model="duration" label="Duration of each round" :items="[ { type: 'DAY', text: '1 day' }, { type: 'WEEK', text: '1 week' }, { type: 'CUSTOM', text: 'Custom' }, ]" />
            </v-col>
            <v-col cols="6">
              <DistanceTextArea
                v-model="request.number_slots" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Number of rounds`" 
                :hint="`The maximum number of rounds to be completed.`"
                />
            </v-col>
            
          </v-row>

          <v-row v-if="duration == 'CUSTOM'">
            <v-col cols="6">
              <DistanceTextArea
                v-if="duration == 'CUSTOM'"
                v-model="request.slot_duration" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Duration of each round (in minutes)`" 
                hint=""
                />
              <v-switch v-if="duration == 'CUSTOM'" v-model="request.combine_activities" label="Combine all activities in this round." />
            </v-col>
            <v-col cols="6">
              <DistanceTextArea
                v-if="duration == 'CUSTOM'"
                v-model="request.slot_overlap" 
                :mode="$helpers.UnitType.NUMBER"
                :label="`Overlap before and after each round (in minutes)`" 
                hint="Give 1 or 2 minutes to prevent DSQs because someone was a few seconds early or late."
                />
            </v-col>
          </v-row>



          <v-alert v-if="endTime" outlined type="info" class="mt-4">
            This challenge ends on <strong>{{ endTime | localDate('ddd L LTS Z') }}</strong>.
          </v-alert>

          <v-alert v-if="!isValid" outlined type="warning" class="mt-4">
            Please complete all fields in this form.
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="visible=false">Cancel</v-btn>
          <v-btn :disabled="!isValid" color="blue darken-1" outlined @click="visible=false;$emit('submit', request)">Create Challenge</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import DistanceTextArea from "@/components/DistanceTextArea";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import ToggleButtonInput from "@/components/ToggleButtonInput";
import { DateTime, Duration } from 'luxon';
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "GenerateLastManStandingDialog",
  components: {
      DistanceTextArea,
      DateAndTimeWithTimeZonePicker,
      ToggleButtonInput,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      visible: false,
      metric: null,
      goalType: null,
      duration: 'DAY',
      request: { 
        name: null,
        activity_type: null,
        start_time: null,
        number_slots: 0,
        combine_activities: true,
        slot_duration: 1440,
        slot_overlap: 0,
        slot_distance: null,
        slot_active_time: null,
        slot_steps: null,
      },
    };
  },
  async mounted() {
  },
  methods: {
    async open() {
      this.visible = true;
    },
  },
  computed: {
    endTime() {
      if (this.event && this.request.start_time && this.request.number_slots && this.request.slot_duration) {
        var start = DateTime.fromISO(this.request.start_time, { zone: this.event.timeZoneOlson });
        var duration = Duration.fromObject({ minutes: this.request.number_slots * this.request.slot_duration });
        return start.plus(duration);
      }
      return null;
    },
    isValid() {
      return this.goalType && this.request.name && this.request.start_time && this.request.number_slots > 0 && this.request.slot_duration > 0 
          && (
            (this.goalType == 'DISTANCE' && this.request.slot_distance > 0)
            || (this.goalType == 'ACTIVE_TIME' && this.request.slot_active_time > 0)
            || (this.goalType == 'STEPS' && this.request.slot_steps > 0)
          );
    }
  },
  watch: {
    duration(val) {
      if (val == 'DAY') {
        this.request.slot_duration = 60*24;
        this.request.combine_activities = true;
      }
      if (val == 'WEEK') {
        this.request.slot_duration = 60*24*7;
        this.request.combine_activities = true;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
  
</style>

