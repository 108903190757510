<template>
  <v-dialog v-if="event" v-model="visible" max-width="650">
    <v-card>
        <v-card-title>
            <span class="headline">Add a 360° wellbeing challenge</span>
        </v-card-title>

        <v-card-text>
          <p>
            A 360° wellbeing challenge allows a very flexible and holistic challenge setup. 
            You can set any combination of achievements as the goal for the challenge.
          </p>
          <p>
            Achievements are claimed based on the honour system by participants. 
            Each achievement can (optionally) get assigned a point value to make some accomplishments count more towards the goal.
          </p>

          <v-text-field
            v-model="request.name" 
            label="Name of the challenge"
            />

          <v-toolbar flat color="white" class="">
              <v-toolbar-title class="ml-0 subtitle">Achievements</v-toolbar-title>
              <v-spacer />
              <v-btn @click="request.achievements.push('')"><v-icon>fa-plus</v-icon> Add</v-btn>
          </v-toolbar>
          
          <div v-for="(item,idx) in request.achievements" :key="idx">
            <v-text-field v-model="request.achievements[idx]" hide-details :label="`Achievement ${idx+1}`" autofocus />
          </div>
          <div v-if="request.achievements.length==0">Please add at least one achievement.</div>
          
          <v-alert v-if="!isValid" outlined type="warning" class="mt-4">
            Please complete all fields in this form.
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="visible=false">Cancel</v-btn>
          <v-btn :disabled="!isValid" color="blue darken-1" outlined @click="visible=false;$emit('submit', request)">Create Challenge</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import DistanceTextArea from "@/components/DistanceTextArea";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import { DateTime, Duration } from 'luxon';
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "GenerateClaimChallengeDialog",
  components: {
      DistanceTextArea,
      DateAndTimeWithTimeZonePicker,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      visible: false,
      request: { 
        name: null,
        achievements: [],
      },
    };
  },
  async mounted() {
  },
  methods: {
    async open() {
      this.visible = true;
    },
  },
  computed: {
    isValid() {
      return this.request.name && this.request.achievements.length>0;
    }
  },
};
</script>
<style lang="scss" scoped>
  
</style>

