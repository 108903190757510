<template>
  <v-dialog v-model="visible" max-width="650">
    <v-card>
        <v-card-title>
            <span class="headline">Add a Training Plan</span>
        </v-card-title>
        <v-alert type="info" tile>This is a BETA feature. Please contact us in case you have questions or find an issue.</v-alert>

        <v-expansion-panels v-if="plans" accordion tile style="max-height: 70vh; overflow-y:auto;">
          <v-expansion-panel v-for="plan in plans" :key="plan.id">
            <v-expansion-panel-header>
              <strong class="shrink">{{ plan.name }}</strong>
              <v-chip color="accent" outlined small class="shrink ml-4">{{ plan.duration }} weeks</v-chip>
              <v-chip color="primary" outlined small class="shrink ml-4">{{ plan.level }}</v-chip>
              <v-spacer/>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <p>
                    Duration: {{ plan.duration }} weeks<br/>
                    Workouts/week: {{ plan.workouts_week }}
                </p>
                <div v-if="plan.description" >
                <h5>Description</h5>
                <VueMarkdown class="markdown-content">{{plan.description}}</VueMarkdown>
                </div>

                <div v-if="plan.goal" >
                <h5>Goal</h5>
                <VueMarkdown class="markdown-content">{{plan.goal}}</VueMarkdown>
                </div>

                <div v-if="plan.prerequisites" >
                <h5>Prerequisites</h5>
                <VueMarkdown class="markdown-content">{{plan.prerequisites}}</VueMarkdown>
                </div>

                <div v-if="plan.author" >
                <h5>Author</h5>
                <VueMarkdown class="markdown-content">{{plan.author}}</VueMarkdown>
                </div>

                <v-btn v-if="!event.races.some(x => x.id == plan.id)" color="blue darken-1" outlined @click="visible=false;$emit('addPlan', plan)">Add Plan</v-btn>
                <span v-else class="text-muted">This plan has already been added.</span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="visible=false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import assetsService from "@/services/assetsService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "SelectTrainingPlanDialog",
  components: {
      VueMarkdown,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      tenant: tenant,
      visible: false,
      plans: null,
      selectedPlanId: null,
    };
  },
  async mounted() {
  },
  methods: {
    async open() {
        this.plans = (await assetsService.getTrainingPlans()).data.data;
        console.log(this.plans);
        this.visible = true;
    },
  },
  computed: {
    availableTrainingPlans() {
        return !this.plans || !this.event ? [] : this.plans.filter(x => !this.event.races.some(y => y.id === x.id));
    }
    
  },
};
</script>
<style lang="scss" scoped>
  
</style>

