<template>
  <div>
    <SelectTrainingPlanDialog ref="trainingPlanDialog" :event="event" @addPlan="addTrainingPlan" />
    <GenerateStreakDialog ref="streakDialog" :event="event" @submit="addStreak" />
    <GenerateLastManStandingDialog ref="lastManStandingDialog" :event="event" @submit="addLastManStanding" />
    <GenerateClaimChallengeDialog ref="wellbeingDialog" :event="event" @submit="addWellbeing" />
    <v-dialog v-if="event && !betaMode" v-model="createDialog" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">Create New Leaderboard</span>
        </v-card-title>
        <v-card-text>
          <h3 class="subtitle">Standard Leaderboard</h3>
          <v-row>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-4 text-center" :elevation="hover ? 8 : 2" @click="addRace">
                  <v-icon size="50" >fal fa-trophy</v-icon>
                  <h3 class="mt-2">Challenge</h3>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-4 text-center" :elevation="hover ? 8 : 2" @click="addInPersonRace">
                  <v-icon size="50" >fal fa-flag-checkered</v-icon>
                  <h3 class="mt-2">In-Person Race</h3>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-4 text-center" :elevation="hover ? 8 : 2" @click="addStepChallenge">
                  <v-icon size="50" >fal fa-shoe-prints</v-icon>
                  <h3 class="mt-2">Step Challenge</h3>
                </v-card>
              </v-hover>
            </v-col>
        </v-row>
        <h3 class="subtitle mt-8">Advanced Leaderboard</h3>
        <v-row>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-4 text-center" :elevation="hover ? 8 : 2" @click="openStreakDialog">
                  <v-icon size="50" >fal fa-calendar-check</v-icon>
                  <h3 class="mt-2">Daily/Weekly Target</h3>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="tenant.id == 'cofi'" cols="4">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-4 text-center" :elevation="hover ? 8 : 2" @click="openWellbeingDialog">
                  <v-icon size="50" >fal fa-head-side-medical</v-icon>
                  <h3 class="mt-2">360° Wellbeing</h3>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="4">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-4 text-center" :elevation="hover ? 8 : 2" @click="openLastManStandingDialog">
                  <v-icon size="50" >fal fa-repeat</v-icon>
                  <h3 class="mt-2">Last One Standing</h3>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="tenant.id != 'cofi'" cols="4">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-4 text-center" :elevation="hover ? 8 : 2" @click="openTrainingPlanDialog">
                  <v-icon size="50" >fal fa-calendar-alt</v-icon>
                  <h3 class="mt-2">Training Plan</h3>
                </v-card>
              </v-hover>
            </v-col>
        </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="createDialog=false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-if="event && betaMode" v-model="createDialog" max-width="650px" max-height="500px" >
      <v-card color="white">
        <v-card-title>
          <span class="headline">Create a New Leaderboard</span>
        </v-card-title>
        <v-card-text class="pb-0 mb-0">
          <p class="pb-0 mb-0">Select the type of leaderboard you'd like to create.</p>
        </v-card-text>
        <v-list>
          <v-divider />
          <BigButton title="Step Challenge" text="Total steps tracked throughout the day." icon="fal fa-shoe-prints" @click="addStepChallenge"  />
          <v-divider />
          <BigButton title="Total Active Time" text="Accumulated time from your activities." icon="fal fa-hourglass-half" @click="addDurationLeaderboard" />
          <v-divider />
          <BigButton title="Total Distance" text="Accumulated (GPS) distance from your activities." icon="fal fa-ruler" @click="addDistanceLeaderboard" />
          <v-divider />
          <BigButton title="Daily/Weekly Target" text="Set a daily or weekly target. Who can complete most days/weeks?" icon="fal fa-calendar-check" @click="openStreakDialog" />
          <v-divider />
          <BigButton title="360° Wellbeing" text="Flexible all-round mental and physical wellbeing challenges." icon="fal fa-head-side-medical" @click="openWellbeingDialog" />
          <v-divider />
          <BigButton title="Last One Standing" text="Set-up hourly, daily, or weekly rounds and see who can keep going the longest." icon="fal fa-repeat" @click="openLastManStandingDialog"/>
          <v-divider />
          <BigButton title="In-Person Race" text="Setup a in-person race leaderboard and upload results." icon="fal fa-flag-checkered" @click="addInPersonRace" />
          <v-divider />
          <BigButton title="Training Plan" text="Add a training plan to support runners in reaching their goals." icon="fal fa-calendar-alt" @click="openTrainingPlanDialog"/>
          <v-divider />
          <BigButton title="Advanced or other" text="Select for more advanced options." icon="fal fa-pencil-ruler" @click="addRace" />
          <v-divider />
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="createDialog=false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="event" v-model="raceDialog" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }} Leaderboard</span>
        </v-card-title>
        <v-alert v-if="error" type="warning" tile outlined class="mx-4 mb-0">
          {{ error }}
        </v-alert>
        <v-form ref="raceForm">
          <v-card-text v-if="simpleMode && !editedItem.id">
            <v-text-field 
              v-model="editedItem.name" 
              ref="name"
              label="Name of the leaderboard" 
              :rules="nameRules"
              required
              />

            <v-radio-group v-if="!isStepChallenge && !editedItem.scoring" v-model="editedItem.scoring" column label="Challenge Type">
              <v-radio v-for="(item, idx) in siteData.scoring_methods.filter(x => x.core)" :key="idx" :value="item.type">
                <template v-slot:label>
                  <span>
                  <strong>{{ item.text }}:</strong>
                  {{ item.description }}
                  </span>
                </template>
              </v-radio>
            </v-radio-group>

            <DistanceTextArea
              v-model="editedItem.dist" 
              :unit="event.unit"
              :label="goalLabel" 
              :mode="goalDistanceUnitType"
              :hint="goalHint"
              :multiplier="goalDistanceMultiplier"
              />
            <v-switch 
              v-if="$helpers.isCumulativeScoring(editedItem) && editedItem.dist > 0"
              v-model="editedItem.overshoot" 
              class="mt-0" 
              label="Allow overshoot" 
              hint="Allow more than 100% completion of goal." 
              persistent-hint 
              />

            <v-select
              v-if="!isStepChallenge && !isCustomActivityTypeChallenge"
              :items="siteData.activities.filter(x => !x.hidden)"
              v-model="editedItem.activity_types" 
              multiple
              item-value="type"
              :item-text="item => $helpers.getActivityTypeLabel(item.type)"
              label="Allowed Sports"
              persistent-hint
              hint="Leave 'All Sports' selected unless you explicitly want to configure this."
            >
              <template v-slot:prepend-item>
                <v-list-item 
                  @click="checkAllActivities"
                  :class="allActivitiesSelected ? 'v-list-item--active':''"
                >
                  <v-list-item-action>
                    <v-icon color="primary">{{ allActivitiesSelected ? 'fa-check-square' : 'fal fa-square' }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>All sports</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            
          </v-card-text>
          <v-expansion-panels v-else  accordion tile v-model="initialPanel" style="max-height: 70vh; overflow-y:auto;">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
              Leaderboard <strong class="ml-1">{{editedItem.name}}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-alert v-if="isStepChallenge && overrideActivityTypesForStepChallenge" outlined type="info">
                Please don't forget to configure <router-link :to="{name:'eventmanagerActivityConversions',params:{id:event.id}}">activity conversions</router-link> to support all enabled sports.
              </v-alert>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field 
                  v-model="editedItem.name" 
                  ref="name"
                  label="Leaderboard name" 
                  :rules="nameRules"
                  required
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <div v-if="isStepChallenge && !overrideActivityTypesForStepChallenge">
                    <v-text-field label="Allowed Sports" disabled value="Daily Steps" hide-details/>
                    <v-btn v-if="eventUtil.showAdvancedLeaderboardOptions()" text small color="primary" @click="forceShowActivityTypes=true">Allow more sports</v-btn>
                  </div>
                  <v-select
                    v-if="!(isStepChallenge || isCustomActivityTypeChallenge) || overrideActivityTypesForStepChallenge"
                    :items="availableActivityTypes"
                    :disabled="eventUtil.hideAdvancedLeaderboardOptions()"
                    v-model="editedItem.activity_types" 
                    multiple
                    item-value="type"
                    :item-text="item => $helpers.getActivityTypeLabel(item.type)"
                    label="Allowed Sports"
                    persistent-hint
                    hint="Leave 'All Sports' selected unless you explicitly want to configure this."
                  >
                    <template v-slot:prepend-item>
                      <v-list-item 
                        @click="checkAllActivities"
                        :class="allActivitiesSelected ? 'v-list-item--active':''"
                      >
                        <v-list-item-action>
                          <v-icon color="primary">{{ allActivitiesSelected ? 'fa-check-square' : 'fal fa-square' }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>All sports</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index <= 2" small>{{ $helpers.getActivityTypeLabel(item.type) }}</v-chip>
                      <span v-else-if="index === 3" class="grey--text text-caption">
                        +{{ editedItem.activity_types.length - 3 }}
                      </span>
                    </template>
                  </v-select>
                  <v-select
                    v-if="isCustomActivityTypeChallenge"
                    :items="event.custom_activity_types"
                    v-model="editedItem.custom_activity_type_ids" 
                    multiple
                    item-value="id"
                    item-text="name"
                    label="Custom Activity Types"
                    />
                </v-col>
                <!-- <v-col cols="12" sm="12" md="12" class="pt-0">
                  <v-btn-toggle v-model="editedItem.team" mandatory>
                    <v-btn :value="false" small>Individual</v-btn>
                    <v-btn :value="true" small>Team/relay</v-btn>
                  </v-btn-toggle>
                </v-col> -->
                <v-col v-if="!isStepChallenge" cols="4" class="pb-0">
                  <v-select
                    :items="siteData.scoring_methods"
                    :disabled="eventUtil.hideAdvancedLeaderboardOptions()"
                    v-model="editedItem.scoring" 
                    item-value="type"
                    item-text="text"
                    :rules="scoringRules"
                    label="Challenge type"
                  ></v-select>
                </v-col>
                <v-col v-if="editedItem.scoring !== 'TIERS'" cols="4" sm="4" md="6" class="pb-0">
                  <DistanceTextArea
                    v-model="editedItem.dist" 
                    :unit="event.unit"
                    :label="goalLabel" 
                    :mode="goalDistanceUnitType"
                    :hint="goalHint"
                    :multiplier="goalDistanceMultiplier"
                    @input="changeDistance"
                    >
                  </DistanceTextArea>
                </v-col>
                <v-col v-if="editedItem.scoring == 'CUSTOM' || editedItem.scoring == 'TIERS'" cols="4" sm="4" md="6" class="pb-0">
                  <v-text-field
                    v-model="editedItem.custom" 
                    label="Custom scoring unit" 
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="selectedScoringMethod" class="grey--text pt-0" style="font-size: 90%;">
                  <strong>{{selectedScoringMethod.text}}: </strong><span>{{selectedScoringMethod.description}}</span>
                </v-col>
                <v-col v-if="$helpers.isCumulativeScoringMethod(editedItem.scoring) && editedItem.dist > 0" cols="4" sm="4" md="6">
                  <v-switch v-model="editedItem.overshoot" class="mt-0" label="Allow overshoot" hint="Allow more than 100% completion of goal." persistent-hint></v-switch>
                </v-col>
                <v-col v-if="editedItem.type !== 'EXTERNAL' && ($helpers.isCumulativeScoring(editedItem) || editedItem.scoring === 'TILES')" cols="6">
                  <v-switch v-model="editedItem.collective" class="mt-0" label="Collective progress" hint="Enable to show the overall progress of all participants combined." persistent-hint></v-switch>
                </v-col>
                <v-col v-if="editedItem.type !== 'EXTERNAL' && editedItem.collective && editedItem.scoring !== 'TILES'" cols="6">
                  <DistanceTextArea
                    v-model="computedCollectiveGoal" 
                    :unit="event.unit"
                    :mode="goalDistanceUnitType"
                    :multiplier="goalDistanceMultiplier"
                    :label="`Collective goal (in ${unitTypeDiplay})`" 
                    hint="Leave at 0 to use the challenge goal"
                    >
                  </DistanceTextArea>
                </v-col>

              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="editedItem.type !== 'EXTERNAL' && !simpleMode">
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-users</v-icon>
              Team
              <v-spacer/>
              <span v-if="editedItem.team" class="shrink mr-4">YES</span>
              <span v-if="!editedItem.team" class="shrink mr-4">NO</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn-toggle v-if="editedItem.type !== 'EXTERNAL'" v-model="computedTeam" class="multiline-btns">
                <v-btn v-for="(type, idx) in siteData.team_types" :key="idx" :value="type.type || '_'" small>
                  <span v-html="type.text.replace('\n', '<br/>')"/>
                </v-btn>
              </v-btn-toggle>
              <v-row v-if="editedItem.team" :key="editedItem.team">
                <v-col cols="12" class="pb-0">
                  <h3>Team configuration</h3>
                  <p class="mb-0">Please note that minimum and maximum team capacity can be configured on event level using the Edit Event option.</p>
                </v-col>
                <!-- <v-col cols="4">
                  <v-switch
                    v-model="editedItem.allow_joining_another_race" 
                    label="Participants may join individual leaderboard"
                    persistent-hint
                    hint="Enable this to allow participants to join an individual leaderboard next to their participation in the team leaderboard."
                  ></v-switch>
                </v-col> -->
                <v-col cols="6">
                  <ToggleButtonInput 
                    v-model="editedItem.team_scoring_aggr" 
                    label="Team Scoring" 
                    small 
                    :items="siteData.team_scoring_aggregation_types" 
                    :hint="(siteData.team_scoring_aggregation_types.find(x => x.type == editedItem.team_scoring_aggr)|| {}).tooltip" 
                    />
                </v-col>
                <v-col cols="6">
                  <v-text-field 
                      v-model.number="computedAggregatedResults" 
                      type="number"
                      min="0" 
                      step="1" 
                      label="Enforced team size" 
                      persistent-hint
                      hint="Enter the number of results to aggregate into the team result.">
                  </v-text-field>
                  <v-alert v-if="editedItem.aggregated" type="info" outlined>
                    This is an advanced option. <strong>Only enable it</strong> when you've read the <a href="https://corporatefitness.helpscoutdocs.com/article/79-team-result-aggregation" target="_blank">FAQ on this topic</a>.
                  </v-alert>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-if="editedItem.scoring == 'RESULT'"
                    :items="siteData.team_gender_results_methods"
                    v-model="editedItem.team_gender_results" 
                    item-value="type"
                    item-text="text"
                    label="Gender sub-teams"
                    persistent-hint
                    hint="Select if and how you want gender based sub-teams."
                  ></v-select>
                </v-col>                
                <v-col v-if="showTeamCategoryOption" cols="12" class="py-0">
                  <v-switch v-model="showTeamCategoryEditor" label="Show only teams of a specific category" :value="editedItem.team_category != null" @click="editedItem.team_category=null" hide-details />
                  <p v-if="showTeamCategoryEditor || editedItem.team_category">Note: this is an advanced feature. Only use when you've read the documentation.</p>
                  <v-text-field 
                      v-if="showTeamCategoryEditor || editedItem.team_category"
                      v-model="editedItem.team_category" 
                      label="Team Category" 
                      persistent-hint
                      hint="Show team leaderboard for a specific category of teams.">
                  </v-text-field>
                </v-col>                
              </v-row>
              <v-alert v-if="editedItem.team == 'RELAY_DISTANCE_SLOTS' || editedItem.team == 'RELAY_TIME_SLOTS'" type="info">
                Relay slots can be configured from the More menu in the leaderboard screen.
              </v-alert>
                              
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="editedItem.type !== 'EXTERNAL' && !simpleMode">
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-filter</v-icon>
              Activity Filter
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>Only activities matching <b>all</b> of these criteria may qualify for this leaderboard.</p>
              <v-row v-if="!isStepChallenge">
                <v-col cols="12" sm="4" md="4">
                  <DistanceTextArea
                    v-model="editedItem.min_dist" 
                    :unit="event.unit"
                    :label="`Min. activity distance (in ${unitTypeDiplay}, inclusive)`" 
                    >
                  </DistanceTextArea>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <DistanceTextArea
                    v-model="editedItem.max_dist" 
                    :unit="event.unit"
                    :label="`Max. activity distance (in ${unitTypeDiplay}, exclusive)`" 
                    >
                  </DistanceTextArea>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <DistanceTextArea
                    v-model="editedItem.min_elevation" 
                    :unit="event.unit"
                    :mode="$helpers.UnitType.ELEVATION"
                    :label="`Min. elevation gain (in ${unitTypeDiplay})`" 
                    >
                  </DistanceTextArea>
                </v-col>
                <v-col v-if="tenant.isDefault" cols="12" sm="12" md="12">
                  <DurationTextArea
                    v-model="editedItem.min_duration_s" 
                    icon="fa-clock"
                    label="Min. duration" 
                    />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <p class="pa-0 text-muted">Optional date restriction. Only set when different from event dates:</p>
                </v-col>
                <v-col cols="6">
                  <DateWithTimeZonePicker v-model="editedItem.from" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Active from (included)" class="pt-0 mt-0" />
                </v-col>
                <v-col cols="6">
                  <DateWithTimeZonePicker v-model="editedItem.till" :timeZone="event.timeZoneOlson" clearable :disabled="!editedItem.from" :minDate="editedItem.from || event.from" :maxDate="event.till" label="Active till (included)" class="pt-0 mt-0" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!simpleMode">
            <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-edit</v-icon>
              Customization
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-alert v-if="!eventUtil.isProPlan()" type="info" class="">
                <div class="d-flex flex-row align-content-center">
                  <p class="mb-0">Leaderboard customization options are only available in the Pro and Enterprise plans.</p>
                  <v-btn class="flex-align-right white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade</v-btn>
                </div>
              </v-alert>

              <div v-if="isRelayRace">
                <h3 class="mt-4">Relay Join Instructions</h3>
                <p class="mb-0">Short summary of what participants should take into account when joining a relay team or assigning slots.</p>
                <MarkdownEditor :disabled="!eventUtil.isProPlan()" v-model="editedItem.relay_join_instructions" :eventId="event.id" label="Relay Join Instructions" />
              </div>
              <h3 class="mt-4">Leaderboard Card</h3>
              <p class="mb-0">Short summary of the leaderboard goal. Leave empty to have it generated for you. This is displayed on the leaderboard card on the event home page.</p>
              <MarkdownEditor :disabled="!eventUtil.isProPlan()" v-model="editedItem.goal_desc" :eventId="event.id" label="Goal Description" />
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field 
                     :disabled="!eventUtil.isProPlan()"
                    v-model="editedItem.color" 
                    label="Card Background Color" 
                  >
                    <template v-slot:append>
                      <v-menu v-model="showColorPickerMenu" right :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div v-if="eventUtil.isProPlan()" :style="swatchStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="colorForBinding" show-swatches mode="hexa" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>                        
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field 
                     :disabled="!eventUtil.isProPlan()"
                    v-model="editedItem.external_url" 
                    label="Link to external url" 
                    />
                </v-col>
              </v-row>

              <h3 class="mt-4">Scoring Rules Description</h3>
              <p class="mb-0">This description (markdown syntax supported) can be used to describe how custom scoring rules are configured for this leaderboard.</p>
              <MarkdownEditor :disabled="!eventUtil.isProPlan()" v-model="editedItem.scoring_desc" :eventId="event.id" label="Scoring Rules" />

              <h3 class="mt-4">Leaderboard Description</h3>
              <p class="mb-0">This message can be used to provide context to results or to add (sponsor) promotion on high value screen estate.</p>
              <MarkdownEditor :disabled="!eventUtil.isProPlan()" v-model="editedItem.result_desc" :eventId="event.id" label="Leaderboard Description" />

              <h3 class="mt-4">Completed Result Description</h3>
              <p class="mb-0">Custom text to congratulate participants on their result. This is displayed on the results page (only for completed results).</p>
              <MarkdownEditor :disabled="!eventUtil.isProPlan()" v-model="editedItem.result_completed_desc" :eventId="event.id" label="Completed Result Message" />

              <div v-if="editedItem.route">
                <h3 class="mt-4">Route Description</h3>
                <p class="mb-0">Detailed description of the route.</p>
                <p class="mb-0 text-muted"><v-icon small color="grey" class="me-1">fa-lock</v-icon> This information is only available to participants of this event.</p>
                <MarkdownEditor :disabled="!eventUtil.isProPlan()" v-model="editedItem.route_desc" :eventId="event.id" label="Route Description" />
              </div>

            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!simpleMode">
            <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-envelope</v-icon>
              Emails
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-alert type="info" outlined>All email customization options will soon move to the new <router-link :to="{name:'eventmanagerEmails', params: {id:event.id}}">Email Customization</router-link> screen.</v-alert>
              <div v-if="editedItem.type !== 'EXTERNAL'">
                <h3>Completion Email</h3>
                <p>Enable this option to trigger an automatic email on completion of the challenge goal. The email contains a Share option which helps to increase engagement of this challenge.</p>
                <v-btn v-if="editedItem.send_completed_email && editedItem.id" outlined color="secondary" class="float-right" @click="sendTestEmail">Send test email</v-btn>
                <v-switch v-model="editedItem.send_completed_email" class="mt-0" label="Send email on completion" hint="Enable to send a (customizable) email whenever this challenge is completed." persistent-hint></v-switch>
                <MarkdownEditor v-if="editedItem.send_completed_email" v-model="editedItem.completed_email_text" :eventId="event.id" label="Completion email" />
              </div>
              <div v-if="editedItem.certificate_doc_id">
                <h3>Certificate Email</h3>
                <p>Enable this option to trigger an automatic email when a certificate is generated. The email contains a Share option which helps to increase engagement of this challenge.</p>
                <v-btn v-if="editedItem.send_certificate_email && editedItem.id" outlined color="secondary" class="float-right" @click="sendCertificateTestEmail">Send test email</v-btn>
                <v-switch v-model="editedItem.send_certificate_email" class="mt-0" label="Send email" hint="Enable to send a (customizable) email whenever a certificate is generated." persistent-hint></v-switch>
                <MarkdownEditor v-if="editedItem.send_certificate_email" v-model="editedItem.certificate_email_text" :eventId="event.id" label="Certificate email" />
              </div>
              <div>
                <h3>Refer-a-Friend Email</h3>
                <p>Customize the email template for the refer-a-friend email for this specific leaderboard. Leave empty to use the default template of the event.</p>
                <v-btn v-if="false && editedItem.send_certificate_email && editedItem.id" outlined color="secondary" class="float-right" @click="sendCertificateTestEmail">Send test email</v-btn>
                <MarkdownEditor v-model="editedItem.referral_email_template" :eventId="event.id" label="Refer-a-Friend Email" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-eye</v-icon>
              Privacy &amp; Visibility
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <h5>Privacy controls</h5>
              <DistanceTextArea
                v-model="editedItem.top_x" 
                :mode="$helpers.UnitType.NUMBER"
                label="Show only the Top X results (and block search and filter)" 
                />

              <h5>Visibility</h5>
              <v-btn-toggle v-model="computedVisibility" class="multiline-btns">
                <v-btn v-for="(type, idx) in siteData.race_visibility" :key="idx" :value="type.type || '_'" small>
                  <span v-html="type.text.replace('\n', '<br/>')"/>
                </v-btn>
              </v-btn-toggle>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="!simpleMode">
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-tools</v-icon>
              Advanced Options
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-switch v-if="editedItem.type !== 'EXTERNAL'" v-model="editedItem.participation" class="mt-0" label="Participation Mode" hint="Check to disable ranking. Leaderboards are sorted by last activity date." persistent-hint></v-switch>
              <v-switch v-if="editedItem.type !== 'EXTERNAL'" v-model="editedItem.calc_gender_pos" class="mt-0" label="Calculate Gender Positions" hint="Check to enable gender based ranking." persistent-hint></v-switch>
              <v-switch v-if="editedItem.collective && editedItem.collective_goal" v-model="editedItem.hide_collective_goal" class="mt-0" label="Hide collective goal" hint="When enabled, the actual collective goal is hidden and only used for the map progress." persistent-hint></v-switch>
              <v-switch v-if="editedItem.scoring == 'BADGES'" v-model="editedItem.personal_goal" class="mt-0" label="Allow personal goals to be used" hint="When on: participants can set their own goals in their profile." persistent-hint></v-switch>

              <v-text-field
                v-model="editedItem.referral_msg" 
                class="mt-0"
                label="Refer-a-friend message"
                persistent-hint
                hint="Overrides the event default (when set). Promotional message to encourage participants to invite their friends."
                />

              <v-text-field
                v-model="editedItem.import_id" 
                class="my-4"
                label="Join Key"
                persistent-hint
                hint="Enter the leaderboard name as it is used during onboarding or in the import file. Use comma's to separate multiple values when multiple values should be mapped to this leaderboard."
                >
              </v-text-field>

              <v-text-field
                v-if="showCustomField"
                v-model="editedItem.custom" 
                label="Custom metric name" 
                >
              </v-text-field>


              <div v-if="editedItem.scoring === 'RESULT' || editedItem.scoring === 'CUSTOM' || editedItem.scoring === 'BADGES'">
              <h5>Type of challenge</h5>
              <v-btn-toggle v-model="computedType" class="multiline-btns">
                <v-btn v-for="(type, idx) in siteData.race_types" :key="idx" :value="type.type || '_'" small>
                  <span v-html="type.text.replace('\n', '<br/>')"/>
                </v-btn>
              </v-btn-toggle>
              </div>

              <v-text-field
                v-if="editedItem.type === 'HYBRID' || editedItem.type === 'EXTERNAL'"
                v-model="editedItem.live_results_url" 
                class="mt-0"
                label="Live results data source"
                persistent-hint
                hint="Enter publicly available url to download live results from."
                />
              <v-alert v-if="editedItem.live_results_url && (!editedItem.live_results_from || !editedItem.live_results_till)" type="warning">
                Make sure to enter both check start and end time to enable live results.
              </v-alert>
              <v-row dense v-if="editedItem.live_results_url">
                <v-col cols="6">
                  <DateAndTimeWithTimeZonePicker v-model="editedItem.live_results_from" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Start time" class="pt-0 mt-0" />
                </v-col>
                <v-col cols="6">
                  <DateAndTimeWithTimeZonePicker v-model="editedItem.live_results_till" :timeZone="event.timeZoneOlson" clearable :minDate="editedItem.till" :maxDate="event.till" label="End time" class="pt-0 mt-0" />
                </v-col>
              </v-row>
              <v-row dense v-if="editedItem.collective">
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.collective_goal_unit" 
                    class="mt-0"
                    label="Custom collective goal unit (override leaderboard default)"
                    persistent-hint
                    hint="Optionally, enter a custom collective goal display unit (e.g. points, laps, etc.)."
                    />
                </v-col>
                <v-col cols="6">
                  <DistanceTextArea
                    v-model="editedItem.collective_goal_multiplier" 
                    :mode="$helpers.UnitType.NUMBER"
                    label="Multiplier for collective progress"
                    hint="Optionally, enter a multiplier to be applied to collective progress" 
                    />                  
                </v-col>
              </v-row>

              <v-select
                v-if="!editedItem.team && (editedItem.type === 'HYBRID' || editedItem.type === 'EXTERNAL' || editedItem.calc_gender_pos)"
                :items="siteData.individual_gender_results_methods"
                v-model="editedItem.individual_gender_results" 
                item-value="type"
                item-text="text"
                label="Gender display"
                persistent-hint
                hint="Select the options you want to show for gender filtering and display."
              ></v-select>

              <v-select
                :items="$helpers.prefixSelectListWith(siteData.secondary_scoring_methods, '- Default -')"
                v-model="editedItem.secondary_scoring" 
                item-value="type"
                item-text="text"
                label="Secondary Scoring Method (optional)"
                placeholder="- Default -"
              ></v-select>

              <v-select
                v-if="editedItem.scoring === 'TILES'"
                :items="$helpers.prefixSelectListWith(siteData.tile_scoring_methods, '- Default (covered tiles) -')"
                v-model="editedItem.tile_scoring" 
                item-value="type"
                item-text="text"
                label="Tile Scoring Method (optional)"
                placeholder="- Default (covered tiles) -"
              ></v-select>


              <v-text-field
                v-if="editedItem.scoring === 'CUSTOM' || editedItem.scoring === 'TIERS'"
                v-model="editedItem.scoring_expr" 
                class="my-4"
                label="Custom scoring expression"
                persistent-hint
                hint="This is an advanced option. Please consult the FAQ before entering an expression."
                >
              </v-text-field>
              <v-select
                v-if="editedItem.scoring === 'CUSTOM' || editedItem.scoring === 'TIERS' || editedItem.team"
                :items="$helpers.prefixSelectListWith(siteData.scoring_aggregation_types, '- Default -')"
                v-model="editedItem.scoring_aggr" 
                item-value="type"
                item-text="text"
                label="Aggregation Method (advanced)"
                placeholder="- Default -"
              ></v-select>
              <v-select
                v-if="editedItem.scoring === 'BADGES'"
                :items="$helpers.prefixSelectListWith(siteData.badge_scoring_methods, '- Default -')"
                v-model="editedItem.badge_scoring" 
                item-value="type"
                item-text="text"
                label="Badge Scoring Method (advanced)"
                placeholder="- Default -"
                />
              <v-select
                :items="$helpers.prefixSelectListWith(siteData.boolean_list, '- Use Event Default -')"
                v-model="editedItem.post_started_feed" 
                item-value="value"
                item-text="text"
                label="Post newly created results to feed (aggregated results only)"
                placeholder="- Use Event Default -"
              />
              <v-select
                :items="$helpers.prefixSelectListWith(siteData.boolean_list, '- Use Event Default -')"
                v-model="editedItem.post_completed_feed" 
                item-value="value"
                item-text="text"
                label="Post completed results to feed"
                placeholder="- Use Event Default -"
              />

              <v-alert v-if="!eventUtil.isProPlan()" type="info" class="mt-12">
                <div class="d-flex flex-row align-content-center">
                  <p class="mb-0">Advanced leaderboard options are available in the Pro and Enterprise plans only.</p>
                  <v-btn class="flex-align-right white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade</v-btn>
                </div>
              </v-alert>

              <v-switch :disabled="!eventUtil.isProPlan()" v-model="editedItem.participants_only" class="mt-0" label="Visible to participants only" hint="Only show this leaderboard to participants (of this leaderboard)." persistent-hint></v-switch>
              <!-- <v-switch :disabled="!eventUtil.isProPlan()" v-model="editedItem.locked" class="mt-0" label="Lock Leaderboard" hint="Enable this to lock the leaderboard and prevent any further changes to the results." persistent-hint></v-switch> -->
              <v-switch :disabled="!eventUtil.isProPlan()" v-model="editedItem.hide_totals" class="mt-0" label="Hide Total Result Count" hint="Enable this to hide the total number of results on top of leaderboards." persistent-hint></v-switch>
              <v-switch :disabled="!eventUtil.isProPlan()" v-model="editedItem.on_demand" class="mt-0" label="On-Demand Leaderboard" hint="Enable this to allow participants to decide when to start this (sub)challenge. Used by training plans but can be used for optional sub-challenges as well." persistent-hint></v-switch>
              <v-switch v-if="editedItem.on_demand" v-model="editedItem.on_demand_everyone" class="mt-0" label="Available for all participants to start On Demand" hint="When on: all participants in this event can start this on-demand leaderboard. When off: only participants who have this on-demand leaderboard in their registration data may start it." persistent-hint></v-switch>


              <v-expansion-panels  :value="editedItem.locked || editedItem.auto_lock_on || editedItem.auto_lock_days ? 0 : null" class="mt-4">
                <v-expansion-panel>
                  <v-expansion-panel-header>Results locking <v-spacer/> <span> <v-chip v-if="editedItem.locked || editedItem.auto_lock_on || editedItem.auto_lock_days" color="primary" small>Configured</v-chip></span> </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-alert v-if="!eventUtil.isProPlan()" type="info" class="">
                      <div class="d-flex flex-row align-content-center">
                        <p class="mb-0">Locking results is available in the Pro and Enterprise plans only.</p>
                        <v-btn class="flex-align-right white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade</v-btn>
                      </div>
                    </v-alert>

                    <v-alert v-if="editedItem.auto_locked" type="info">This leaderboard is currently locked due to its current configuration.</v-alert>
                    <p>Configure when you would like this leaderboard to stop accepting changes. This can be used to prevent leaderboard changes after announcing the winners of the leaderboard.</p>
                      <v-text-field 
                          :disabled="!eventUtil.isProPlan()"
                          v-model.number="computedAutoLockDays" 
                          type="number"
                          min="0" 
                          step="1" 
                          label="Days after end of event." 
                          persistent-hint
                          hint="Auto lock so many days after the event has ended."
                          />
                    <DateAndTimeWithTimeZonePicker :disabled="!eventUtil.isProPlan()" v-model="editedItem.auto_lock_on" :timeZone="event.timezone" label="Auto-lock date" hint="Select the timestamp when you want this leaderboard to be locked." />
                    <v-switch :disabled="!eventUtil.isProPlan()" v-model="editedItem.locked" class="mt-0" label="Lock results" hint="Enable this to lock this leaderboard and prevent any further changes to the results." persistent-hint></v-switch>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeRaceDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveRace">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import assetsService from "@/services/assetsService";
import eventManagerService from "@/services/eventManagerService";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import ToggleButtonInput from "@/components/ToggleButtonInput";
import MarkdownEditor from "@/components/MarkdownEditor";
import BigButton from '@/components/BigButton.vue'
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import SelectTrainingPlanDialog from './_SelectTrainingPlanDialog.vue'
import GenerateStreakDialog from './_GenerateStreakDialog.vue'
import GenerateLastManStandingDialog from './_GenerateLastManStandingDialog.vue'
import GenerateClaimChallengeDialog from './_GenerateClaimChallengeDialog.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    BigButton,
    DistanceTextArea,
    DurationTextArea,
    DateWithTimeZonePicker,
    DateAndTimeWithTimeZonePicker,
    MarkdownEditor,
    ToggleButtonInput,
    SelectTrainingPlanDialog,
    GenerateStreakDialog,
    GenerateLastManStandingDialog,
    GenerateClaimChallengeDialog,
  },
  props: {
    event: Object,
    activeRace: Object,
    simple: Boolean,
  },
  data() {
    return {
      siteData: siteData,
      process: process,
      tenant: tenant,
      valid: true,
      error: null,
      //races: [],
      initialPanel: 0,
      nameRules: [
        v => !!v || "Please enter the leaderboard name",
      ],
      scoringRules: [
        v => !!v || "Please select the scoring method for this leaderboard",
      ],
      numberRules: [
        v => v > 0 || "Please enter a positive number",
      ],
      raceDialog: false,
      createDialog: false,
      showTeamCategoryEditor: false,
      showColorPickerMenu: false,
      forceShowActivityTypes: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        //dist: 0,
        //min_dist: 0,
        //max_dist: 0,
      },
      defaultItem: {
        name: '',
        scoring: 'RESULT',
        send_completed_email: true,
        //dist: 0,
        //min_dist: 0,
        //max_dist: 0,
      },
    };
  },
  async mounted() {
  },
  methods: {

    createRace() {
      console.log('Showing create race dialog');
      this.createDialog = true;
    },

    addRaceLeaderboard() {
      this.addRace({ name: 'Fastest Time', scoring: 'RESULT', dist: 0 });
    },
    addDistanceLeaderboard() {
      this.addRace({ name: 'Distance Challenge', scoring: 'DISTANCE', dist: 0 });
    },
    addDurationLeaderboard() {
      this.addRace({ name: 'Active Time Challenge', scoring: 'TIME', dist: 0 });
    },
    addStepLeaderboard() {
      this.addRace({ name: 'Step Challenge', scoring: 'STEPS', dist: 0, activity_types: ['DAILY_SUMMARY'] });
    },

    addRace(race) {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, race || this.defaultItem);
      this.editedItem.send_completed_email = true;
      this.createDialog = false;
      this.raceDialog = true;
      this.$nextTick(() => {
        setTimeout(() => {
        console.log('this.$refs.name', this.$refs.name);
          if (this.$refs.name) this.$refs.name.focus();
        });
      });
    },

    addRaceAndSave(race) {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, race);
      this.editedItem.send_completed_email = true;
      if (race.scoring === 'RESULT' && this.event.type === 'RESULTS') {
        this.editedItem.type = 'EXTERNAL';
      }
      else if (race.scoring === 'RESULT' && this.event.type === 'HYBRID') {
        this.editedItem.type = 'HYBRID';
      }
      this.saveRace();
      console.log('Added race', race);
    },

    addInPersonRace() {
      this.addRace();
      this.editedItem.type = 'EXTERNAL';
      this.editedItem.scoring = 'RESULT';
    },

    addStepChallenge() {
      this.addRace();
      this.editedItem.activity_types = ['DAILY_SUMMARY'];
      this.editedItem.scoring = 'STEPS';
      this.editedItem.dist = 0;
    },
    
    editRace(item) {
      this.editedIndex = this.event.races.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.raceDialog = true
    },

    changeDistance () {
      console.log('change DIST', this.editedItem.dist);
      this.$forceUpdate(); // this is required somehow
    },

    closeRaceDialog () {
      this.raceDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    openStreakDialog() {
      this.createDialog=false; 
      this.$refs.streakDialog.open();
    },
    openLastManStandingDialog() {
      this.createDialog=false; 
      this.$refs.lastManStandingDialog.open();
    },
    openTrainingPlanDialog() {
      this.createDialog=false; 
      this.$refs.trainingPlanDialog.open();
    },
    openWellbeingDialog() {
      this.createDialog=false; 
      this.$refs.wellbeingDialog.open();
    },

    async addTrainingPlan(plan) {
      console.log('Adding new plan', plan);
      var request = {
        training_plan_id: plan.id,
      }
      var response = (await eventManagerService.addTrainingPlan(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added training plan.');
      if (response.status === 'OK') {
        await this.eventUtil.refresh();
      }
    },

    async addStreak(request) {
      console.log('Adding new streak', request);
      var response = (await eventManagerService.addStreakChallenge(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added challenge.');
      if (response.status === 'OK') {
        await this.eventUtil.refresh();
      }
    },

    async addLastManStanding(request) {
      console.log('Adding last one standing', request);
      var response = (await eventManagerService.addLastManStandingChallenge(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added challenge.');
      if (response.status === 'OK') {
        await this.eventUtil.refresh();
      }
    },

    async addWellbeing(request) {
      console.log('Adding wellbeing', request);
      var response = (await eventManagerService.addClaimChallenge(this.event.id, request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully added challenge.');
      if (response.status === 'OK') {
        await this.eventUtil.refresh();
      }
    },

    saveRace() {
      if (this.$refs.raceForm && !this.$refs.raceForm.validate()) {
        this.error = 'Please fix all validation issues first';
        return;
      }
      if (this.editedItem && this.editedItem.dist) {
        this.editedItem.dist = Math.round(this.editedItem.dist);
      }
      if (this.editedIndex > -1) {
        Object.assign(this.event.races[this.editedIndex], this.editedItem);
      } else {
        if (!this.event.races) {
          this.event.races = [this.editedItem];  
        }
        else {
          this.event.races.push(this.editedItem);
        }
      }
      //this.event.races = this.races;
      this.closeRaceDialog();
      this.$emit('save', this.editedItem);
    },
    
    async sendTestEmail() {
      // save RaceId first as saving will reset this (when closing the dialog)
      const raceId = this.editedItem.id;
      // save first to include textual changes!
      await this.saveRace();
      // now send the email
      const response = await eventManagerService.sendRaceCompletedEmail(this.event.id, raceId);
      this.$helpers.toastResponse(this, response.data, 'The test email is on its way! Please check your inbox in a few minutes from now.');
    },
    
    async sendCertificateTestEmail() {
      // save RaceId first as saving will reset this (when closing the dialog)
      const raceId = this.editedItem.id;
      // save first to include textual changes!
      await this.saveRace();
      // now send the email
      const response = await eventManagerService.sendRaceCertificateEmail(this.event.id, raceId);
      this.$helpers.toastResponse(this, response.data, 'The test email is on its way! Please check your inbox in a few minutes from now.');
    },

    checkAllActivities() {
      this.editedItem.activity_types = [];
    },

  },
  computed: {
    betaMode() {
      return process.env.NODE_ENV !== 'production';
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    simpleMode() {
      return this.simple || this.eventUtil.hideAdvancedLeaderboardOptions();
    },
    isStepChallenge() {
      return this.editedItem && this.editedItem.scoring == "STEPS";
    },
    isCustomActivityTypeChallenge() {
      return this.editedItem && this.editedItem.activity_types && this.editedItem.activity_types.length == 1 &&  this.editedItem.activity_types[0] == "CUSTOM";
    },
    allActivitiesSelected() {
      return !this.editedItem.activity_types || this.editedItem.activity_types.length == 0;
    },
    computedAutoLockDays:{
      get () {
        return this.editedItem ? this.editedItem.auto_lock_days : null
      },
      set (value) {
        this.editedItem.auto_lock_days = value === /*must be triple!*/ 0 ? null : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
        this.$forceUpdate();
      }
    },

    goalLabel() {
      if (!this.editedItem || this.editedItem.scoring == null || this.editedItem.scoring == "RESULT" || this.editedItem.scoring == "TRACK") {
        return `Goal distance (in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "DISTANCE"){
        return `Total distance goal (for entire challenge, in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "STEPS"){
        return `Total step goal (for entire challenge)`;
      }
      else if (this.editedItem.scoring == "ELEVATION" || this.editedItem.scoring == "ELEVATION_LOSS"){
        return `Total elevation goal (in ${this.unitTypeDiplay})`;
      }
      else if (this.editedItem.scoring == "CALORIES"){
        return `Total calorie goal (in kcal)`;
      }
      else if (this.editedItem.scoring == "TIME"){
        return `Total time goal (for entire challenge, in hours)`;
      }
      else if (this.editedItem.scoring == "BADGES"){
        return `Total badges to earn`;
      }
      else if (this.editedItem.scoring == "STAIRS"){
        return `Total number of stairs goal`;
      }
      else if (this.editedItem.scoring == "CUSTOM"){
        return `Total ${this.editedItem.custom || 'score'} goal`;
      }
      return null;
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New' : 'Edit'
    },
    goalDistanceMultiplier() {
      if (this.editedItem.scoring == "TIME") {
        return 3600;
      }
      return null;
    },
    goalDistanceUnitType() {
      if (this.editedItem != null && (this.editedItem.scoring == "CALORIES"  || this.editedItem.scoring == "TILES"  || this.editedItem.scoring == "TIME" || this.editedItem.scoring == "STAIRS" || this.editedItem.scoring == "BADGES" || this.editedItem.scoring == "CUSTOM")){
        return this.$helpers.UnitType.NUMBER;
      }
      return this.distanceUnitType;
    },
    goalHint() {
      return this.editedItem.dist === 0 ? 'Open challenge goal' : null;
    },
    distanceUnitType() {
      if (this.editedItem != null && (this.isStairClimbing || this.editedItem.scoring == "BADGES" || this.editedItem.scoring == "STEPS" || this.editedItem.scoring == "TILES" || this.editedItem.scoring == "CUSTOM")) {
        return this.$helpers.UnitType.NUMBER;
      }
      else if (this.editedItem != null && (this.editedItem.scoring == "ELEVATION" || this.editedItem.scoring == "ELEVATION_LOSS")){
        return this.$helpers.UnitType.ELEVATION;
      }
      return this.$helpers.UnitType.DISTANCE;
    },
    unitTypeDiplay() {
      if (this.editedItem != null && this.isStairClimbing) {
        return 'stairs';
      }
      if (this.editedItem != null && this.editedItem.scoring == "BADGES") {
        return 'badges';
      }
      if (this.editedItem != null && this.editedItem.scoring == "STEPS") {
        return 'steps';
      }
      if (this.editedItem != null && this.editedItem.scoring == "TILES") {
        return 'tiles';
      }
      else if (this.editedItem != null && (this.editedItem.scoring == "ELEVATION" || this.editedItem.scoring == "ELEVATION_LOSS")){
        return this.event == null || this.event.unit == 'METRIC' ? 'm' : 'ft';
      }
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    },
    isRelayRace() {
      return this.editedItem && (this.editedItem.team == 'RELAY_TIME_SLOTS' || this.editedItem.team == 'RELAY_DISTANCE_SLOTS')
    },
    showCustomField() {
      return this.editedItem && ((this.editedItem.activity_types || []).some(x => x === 'VOLUNTEERING' || x === 'OTHER'))
    },
    availableActivityTypes() {
      if (this.editedItem && (this.isStepChallenge || this.editedItem.scoring == "BADGES" || this.editedItem.scoring == "CUSTOM" || this.editedItem.scoring === 'TIERS')) {
        return siteData.activities.filter(x => !x.hidden || x.type == 'DAILY_SUMMARY');
      }
      return siteData.activities.filter(x => !x.hidden);
    },
    overrideActivityTypesForStepChallenge() {
      return this.forceShowActivityTypes 
          || (this.editedItem && this.editedItem.scoring === 'STEPS'  && this.editedItem.activity_types && this.editedItem.activity_types.length > 1);
    },
    isStairClimbing() {
      return this.editedItem != null && this.editedItem.activity_types && this.editedItem.activity_types.length == 1 && this.editedItem.activity_types[0] == 'STAIR_CLIMBING';
    },
    selectedScoringMethod() {
      return this.editedItem == null || this.editedItem.scoring == null ? null : siteData.scoring_methods.find(x => x.type == this.editedItem.scoring);
    },
    showTeamCategoryOption() {
      return this.event && (
        (this.event.join_udf_1_field && this.event.join_udf_1_field.team_category)
        || (this.event.join_udf_2_field && this.event.join_udf_2_field.team_category)
        || (this.event.join_udf_3_field && this.event.join_udf_3_field.team_category)
      );
    },
    computedTeam:{
      get () {
        return this.editedItem && this.editedItem.team ? this.editedItem.team : "_"
      },
      set (value) {
        this.editedItem.team = value === "_" ? null : value;
        this.$forceUpdate();
      }
    },
    computedType:{
      get () {
        return this.editedItem && this.editedItem.type ? this.editedItem.type : "_"
      },
      set (value) {
        this.editedItem.type = value === "_" ? null : value;
        this.$forceUpdate();
      }
    },
    computedVisibility:{
      get () {
        return this.editedItem && this.editedItem.visibility ? this.editedItem.visibility : "_"
      },
      set (value) {
        this.editedItem.visibility = value === "_" ? null : value;
        this.$forceUpdate();
      }
    },
    computedAggregatedResults:{
      get () {
        return this.editedItem ? this.editedItem.aggregated : null
      },
      set (value) {
        this.editedItem.aggregated = value === /*must be triple!*/ 0 ? null : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
        this.$forceUpdate();
      }
    },
    computedCollectiveGoal:{
      get () {
        return this.editedItem ? this.editedItem.collective_goal : null
      },
      set (value) {
        this.editedItem.collective_goal = value === /*must be triple!*/ 0 ? null : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },
    colorForBinding: {
      get() {
        console.log('GET', this.editedItem.color);
        if (!this.editedItem.color || this.editedItem.color.length == 0) {
          return "#FFFFFF";
        }
        return this.editedItem.color;
      },
      set(value) {
        console.log('SET', value);
        if (value && value.length > 7) {
          value = value.substring(0, 7).toUpperCase();
          if (value === '#FFFFFF') {
            value = null;
          }
        }
        this.editedItem.color = value;
        this.$forceUpdate();
      }
    },
    swatchStyle() {
      const { editedItem, showColorPickerMenu } = this
      return {
        backgroundColor: editedItem.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        border: 'solid 1px #ccc',
        borderRadius: showColorPickerMenu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    event (val) {
      //this.races = val.races;
      console.log('event', val);
    },
    raceDialog (val) {
      val || this.closeRaceDialog()
    },
  },
};
</script>
<style lang="scss">
</style>

